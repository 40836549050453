import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import { Alert, Button, Col, Container, Row } from "react-bootstrap"
import {
  sizeBackground,
  row,
  backgroundOptions,
  container,
  titre,
  form,
  formTitle,
  formPlaceHolder,
  formPlaceHolderTextarea,
  formButton,
  colPhone,
  colForm,
  alert,
  vhu,
  pref,
  rowImage,
  divButton
} from "./styles/form.module.css"
import SlideButton from "./common/slideButton"

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const SlideEpaviste = ({ para, backgroundImage }) => {
  const [formFields, setFieldsForm] = useState({
    nom: "",
    codePostal: "",
    email: "",
    phone: "",
    message: "",
  })

  const [alertSuccess, setAlertSuccess] = useState("none")
  const [alertDanger, setAlertDanger] = useState("none")

  const handleSubmit = (e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formFields }),
    })
      .then(() => setAlertSuccess("block"))
      .catch((_) => setAlertDanger("block"))

    e.preventDefault()
  }

  const handleChange = (e) =>
    setFieldsForm({ ...formFields, [e.target.name]: e.target.value })

  const data = useStaticQuery(graphql`
    query {
      epave: file(relativePath: { eq: "depanneur-Dulin-en-action.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      destruction: file(relativePath: { eq: "destruction_véhicule.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      casse: file(relativePath: { eq: "casse_auto.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      centre: file(relativePath: { eq: "centre_vhu.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      rachat: file(relativePath: { eq: "rachat-auto.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      vhu: file(relativePath: { eq: "epaviste-vhu-dulin.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      prefecture: file(
        relativePath: { eq: "epaviste-agree-prefecture-vhu.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const selectBackgroundImage = () => {
    if(backgroundImage === "destruction")
      return data.destruction;
    
    if(backgroundImage === "casse")
      return data.casse;
    
    if(backgroundImage === "centre")
      return data.centre
    
    if(backgroundImage === "rachat")
      return data.rachat

    return data.epave;
  }

  return (
    <BackgroundImage
      Tag="section"
      className={`${sizeBackground} ${backgroundOptions}`}
      fluid={selectBackgroundImage().childImageSharp.fluid}
    >
      <Container fluid={true} className={container}>
        <Row className={row}>
          <Col xs={12} md={6} className={colPhone}>
            <Row className={titre}>
              {para}
              <br />
              prenez contact avec l'un de nos conseiller.
            </Row>
            <Row md className={divButton}>
              <SlideButton />
            </Row>
          </Col>
          <Col xs={12} md={5} className={colForm}>
            <form
              className={form}
              onSubmit={handleSubmit}
              data-netlify="true"
              name="contact"
            >
              <p className={formTitle}>Contacter notre centre VHU</p>
              <Row className={rowImage}>
                <Col xs md>
                  <Img fluid={data.vhu.childImageSharp.fluid} alt="logo_centre_vhu" className={vhu}/>
                </Col>
                <Col xs md>
                 <Img fluid={data.prefecture.childImageSharp.fluid} alt="logo_ministere_agriculture" className={pref}/> 
                </Col>
              </Row>
              <input
                className={formPlaceHolder}
                type="text"
                placeholder="nom"
                value={formFields.nom}
                name="nom"
                onChange={handleChange}
                pattern="[a-zA-Z]{2,}"
                title="Deux lettres minimum est requis."
              />
              <input
                className={formPlaceHolder}
                type="text"
                placeholder="code postal"
                value={formFields.codePostal}
                name="codePostal"
                onChange={handleChange}
                required={true}
                pattern="[0-9]{5}"
                title="exemple = 75000"
              />
              <input
                className={formPlaceHolder}
                type="email"
                placeholder="émail"
                value={formFields.email}
                name="email"
                onChange={handleChange}
                required={true}
              />
              <input
                className={formPlaceHolder}
                type="text"
                placeholder="téléphone"
                value={formFields.phone}
                name="phone"
                onChange={handleChange}
                pattern="[0-9]{10}"
                title="exemple = 0123456789"
              />
              <textarea
                className={formPlaceHolderTextarea}
                placeholder="votre demande"
                value={formFields.message}
                name="message"
                onChange={handleChange}
                required={true}
              />
              <Button variant="success" className={formButton} type="submit">
                Se faire rappeler
              </Button>
              <Alert
                variant="success"
                className={alert}
                style={{ display: `${alertSuccess}` }}
              >
                Votre message à bien été envoyé, nos services reviennent vers
                vous très rapidement.
              </Alert>
              <Alert
                variant="danger"
                className={alert}
                style={{ display: `${alertDanger}` }}
              >
                Veuillez réessayer ultérieurement, votre message n'a pas pu être
                envoyé, nous somme navrée.
              </Alert>
            </form>
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  )
}

export default SlideEpaviste
