// extracted by mini-css-extract-plugin
export var vhu = "form-module--vhu--UJtCo";
export var pref = "form-module--pref--XJfBu";
export var sizeBackground = "form-module--sizeBackground--tMEkO";
export var container = "form-module--container--KWy0o";
export var row = "form-module--row--Vc-kV";
export var titre = "form-module--titre--ufoEW";
export var button = "form-module--button--iXE8f";
export var colForm = "form-module--colForm--yLjNb";
export var form = "form-module--form--kBY3X";
export var formTitle = "form-module--form-title--vK4+N";
export var formPlaceHolder = "form-module--form-placeHolder--wfdMs";
export var formPlaceHolderTextarea = "form-module--form-placeHolder-textarea--p73Tg";
export var formButton = "form-module--form-button--rXw4i";
export var paraSlider = "form-module--para-slider--cH-n6";
export var linkSlider = "form-module--link-slider--H5YTm";
export var divButton = "form-module--div-button--93AbJ";
export var colPhone = "form-module--colPhone--yjXSu";
export var rowImage = "form-module--row-image--GqMJq";
export var backgroundOptions = "form-module--backgroundOptions--G2R38";
export var alert = "form-module--alert--SQAVU";