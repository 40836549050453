import { useState, useEffect } from 'react';

const getWidthWindowDimensions = () => {
  const { innerWidth: width } =  typeof window !== "undefined" && window;
  return {
    width,
  };
}

export default function useWidthWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWidthWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWidthWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}