import React from "react"
import "../styles/global.css"
import "../styles/contact.css"
import Layout from "../components/common/layout"
import Hr from "../components/common/hr"
import { Col, Container, Row } from "react-bootstrap"
import Seo from "../components/common/seo"
import SlideEpaviste from "../components/landing/slideEpaviste"

const Contact = () => (
  <Layout>
    <Seo
      title="Contactez votre épaviste pour tout besoin d'enlèvement, de destruction et de recyclage de véhicules - Epaviste Dulin"
      description="Formulaire pour contacter l'épaviste Dulin pour toutes demandes d'enlèvement, de destruction et de recyclage de véhicules."
    />
    <Hr />
    <SlideEpaviste/>
    <Hr />
  </Layout>
)

export default Contact
