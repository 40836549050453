import React from "react"
import { Button } from "react-bootstrap"
import { button } from "./slideButton.module.css"
import UseWidthWindowDimensions from "../../../hook/useWidthWindowDimensions"

const SlideButton = () => {

    const { width } = UseWidthWindowDimensions();
  
    const disabledButton = width > 765

    return (
        <Button
        href={"tel:0749387047"}
        variant="success"
        className={button}
        disabled={disabledButton}
      >
        07 49 38 70 47
      </Button>
    )
}

export default SlideButton;